(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/draggable-container/assets/javascripts/draggable-container.js') >= 0) return;  svs.modules.push('/components/lb-ui/draggable-container/assets/javascripts/draggable-container.js');
"use strict";


const {
  useEffect,
  useRef,
  useState
} = React;
const {
  clx
} = svs.components.lbUtils;
const SCROLL_MULTIPLIER = 2;
const DraggableContainer = _ref => {
  let {
    ariaLabel,
    children,
    className,
    indexTab,
    preventClickPropagation = true
  } = _ref;
  const containerRef = useRef(null);
  const childrenRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  useEffect(() => {
    const containerEl = containerRef.current;
    const contentEl = childrenRef.current;
    const checkScrollPosition = () => {
      if (containerRef.current) {
        const canScrollLeft = containerEl.scrollLeft > 0;
        const canScrollRight = containerEl.scrollLeft < containerEl.scrollWidth - containerEl.clientWidth;
        setCanScrollLeft(canScrollLeft);
        setCanScrollRight(canScrollRight);
      }
    };
    const checkScrollable = () => {
      if (containerEl && contentEl) {
        const containerWidth = containerEl.offsetWidth;
        const childrenWidth = contentEl.scrollWidth;
        setIsScrollable(childrenWidth > containerWidth);
        checkScrollPosition();
      }
    };
    checkScrollable();
    window.addEventListener('resize', checkScrollable);
    containerEl.addEventListener('scroll', checkScrollPosition);
    return () => {
      window.removeEventListener('resize', checkScrollable);
      containerEl.removeEventListener('scroll', checkScrollPosition);
    };
  }, []);
  const handleClick = e => {
    if (preventClickPropagation) {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  const startDrag = e => {
    e.preventDefault();
    setIsDragging(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
  };
  const stopDrag = () => setIsDragging(false);
  const onDrag = e => {
    if (!isDragging) {
      return;
    }
    e.preventDefault();
    const x = e.pageX - e.currentTarget.offsetLeft;
    const newPositionX = (x - startX) * SCROLL_MULTIPLIER;
    e.currentTarget.scrollLeft = scrollLeft - newPositionX;
  };
  const validatedIndexTab = typeof indexTab === 'number' && !isNaN(indexTab) ? indexTab : 0;
  const dragCursor = isDragging ? 'grabbing' : 'grab';
  const cursor = isScrollable ? dragCursor : 'auto';
  return React.createElement("div", {
    "aria-label": ariaLabel,
    className: clx('pg_draggable_container', {
      'pg_draggable_container--scrollable': isScrollable,
      'pg_draggable_container--can-scroll-left': canScrollLeft,
      'pg_draggable_container--can-scroll-right': canScrollRight
    }, className),
    onClick: handleClick,
    onMouseDown: startDrag,
    onMouseLeave: stopDrag,
    onMouseMove: onDrag,
    onMouseUp: stopDrag,
    ref: containerRef,
    style: {
      cursor
    },
    tabIndex: isScrollable ? validatedIndexTab : -1
  }, React.createElement("div", {
    className: "pg_draggable_container__content",
    ref: childrenRef
  }, children));
};
setGlobal('svs.components.lbUi.DraggableContainer', DraggableContainer);

 })(window);